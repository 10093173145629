
/** Token Key */
const AUTH_TOKEN_KEY = '__auth_token__';

const storageHandler = typeof(Storage) !== "undefined" ? localStorage : false;

/**
 * Sets the token in local storage
 */
function setToken(token) {
    try {
        localStorage.setItem(AUTH_TOKEN_KEY, token);
    } catch (e) {
        if (e.name === 'QuotaExceededError') {
            console.error(
                'There was an error saving your login information. Please clear your browser cache and try again.'
            );
        }
    }
}

export async function getApiDomainToken() {
    let token = getApiSessionToken();
    if (token !== null) {
        return token;
    }

    try {

        const response = await window.fetch(`${import.meta.env.VITE_EDUFOCAL_API}/echo`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                accept: 'text/plain',
            }
        });

        if (!response.ok) {
            return false;
        }

        const text = await response.text();
        setApiSessionToken(text);
        return text;

    } catch (e) {
        return false;
    }
}

export function setApiSessionToken(token) {
    return setTokenToCookie(token);
}

export function removeApiSessionToken() {
    return removeTokenFromCookie();
}

// Function to get a token from sessionStorage
function getApiSessionToken() {
    return getTokenFromCookie();
}

function removeTokenFromLocalStorage() {
    if (typeof(Storage) !== "undefined") {
        storageHandler.removeItem(AUTH_TOKEN_KEY);
        return true;
    }
    return false;
}

/**
 * Local Storage specific implementations
 * @returns {string|null}
 */
function getTokenFromLocalStorage() {
    if (typeof(Storage) !== "undefined") {
        let token = storageHandler.getItem(AUTH_TOKEN_KEY);
        if (token === "null") {
            return null;
        }
        return token;
    }
    return null; // sessionStorage is not supported
}

function setTokenToLocalStorage(token) {
    if (typeof(Storage) !== "undefined") {
        if (token === null) {
            storageHandler.removeItem(AUTH_TOKEN_KEY);
        }
        try {
            storageHandler.setItem(AUTH_TOKEN_KEY, token);
            return true;
        } catch (e) {
            if (e.name === 'QuotaExceededError') {
                console.error(
                    'There was an error saving your login information. Please clear your browser cache and try again.'
                );
            }
        }
    }
    return false; // sessionStorage is not supported
}

function removeTokenFromCookie() {
    document.cookie = `${AUTH_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    return true;
}


function getTokenFromCookie() {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(`${AUTH_TOKEN_KEY}=`) === 0) {
            return cookie.substring(`${AUTH_TOKEN_KEY}=`.length, cookie.length);
        }
    }
    return null;
}


function setTokenToCookie(token) {
    if (token === null) {
        // Remove the cookie
        document.cookie = `${AUTH_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    } else {
        // Set the cookie, you may also want to set additional properties like expire time, domain, path, etc.
        document.cookie = `${AUTH_TOKEN_KEY}=${token}; path=/;`;
    }
    return true;
}



/**
 * Logout user
 */

export function logout() {
    removeApiSessionToken();
    //clean up all these cookies that may have existed at some point
    document.cookie = `ef_autologin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `ef_bf_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `ef_ci_csrf_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `bf_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `autologin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `ci_csrf_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    window.location.reload();
}

/**
 * Create a loading spinner HTML element
 */
export function createLoadingSpinner() {
    const loadingSpinner = document.createElement('div');
    loadingSpinner.classList.add('loader');
    return loadingSpinner;
}

/**
 * Returns a new function that can only be called once every wait milliseconds.
 * If the function is called multiple times within that time period,
 * the function will only be invoked once.
 * @param {Function} func - The function to be debounced
 * @param {number} wait - The time in milliseconds to wait before invoking the function
 * @returns
 */
export function debounce(func, wait) {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}